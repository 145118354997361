<template>
  <a-card :bordered="false" class="card-area">
    <div class="search">
      <!-- 搜索区域 -->
      <!--      <div class="items" style="flex: none">-->
      <!--        <div class="one-item">-->
      <!--          <a-input-->
      <!--              class="input"-->
      <!--              v-model="queryParams.keyword"-->
      <!--              placeholder="分类名称/描述"-->
      <!--              allowClear-->
      <!--          />-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="operator">
        <a-button type="primary" @click="search(1)">查询</a-button>
<!--        <a-button style="margin-left: 8px" @click="reset">重置</a-button>-->
      </div>
<!--      <a-button v-hasPermission="'dim:add'" type="primary" @click="add" style="margin-left:20px;" icon="plus">-->
<!--        新增-->
<!--      </a-button>-->
    </div>
    <a-table
        ref="TableInfo"
        size="small"
        :bordered="true"
        :columns="columns"
        rowKey="id"
        :dataSource="dataSource"
        :pagination="pagination"
        :loading="loading"
        @change="handleTableChange"
        :scroll="{ x: 1000 }"
    >
      <template slot="status" slot-scope="text, record">
        <a-tag :color="record.status | dictName('validStatus', 'color')" style="cursor: pointer">
          {{ record.status| dictName("validStatus") }}
        </a-tag>
      </template>
      <template slot="score" slot-scope="text, record">
        <a-tag v-if="text<0" color="#FF9900">
          {{text}}
        </a-tag>
        <a-tag v-else color="green">
          +{{text}}
        </a-tag>
      </template>
      <template slot="mode" slot-scope="text, record">
        <a-tag :color="record.mode | dictName('taskMode', 'color')" style="cursor: pointer">
          {{ record.mode| dictName("taskMode") }}
        </a-tag>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button type="primary" icon="edit" size="small" v-has-permission="'dim:edit'" @click="modify(record)">
          修改
        </a-button>
      </template>
    </a-table>
    <modify-modal ref="modifyModal" @success="editSuccess"/>
  </a-card>
</template>
<script>
import {ListMixin} from "@/mixin/list-mixin";
import modifyModal from "./modifyConfigtaskModal";

export default {
  name: "ConfigTaskList",
  mixins: [ListMixin],
  components: {modifyModal},
  data() {
    return {
      listGetUrl: "taskConfig/list",
      listMethod: "get",
    };
  },
  computed: {
    textColor() {
      return this.$store.state.setting.color;
    },
    columns() {
      let {sortedInfo, filteredInfo} = this;
      sortedInfo = sortedInfo || {};
      filteredInfo = filteredInfo || {};
      return [
        {
          title: "ID",
          width: 80,
          align: "center",
          dataIndex: "id",
        },

        {
          title: "任务编码",
          width: 180,
          align: "center",
          dataIndex: "taskCode",
        },
        {
          title: "任务名称",
          align: "center",
          width: 150,
          dataIndex: "taskName",
        },
        {
          title: "状态",
          width: 80,
          dataIndex: "status",
          align: "center",
          scopedSlots: {customRender: "status"},
        },
        {
          title: "任务类型",
          width: 80,
          dataIndex: "mode",
          align: "center",
          scopedSlots: {customRender: "mode"},
        },
        {
          title: "每日最多次数",
          width: 120,
          align: "center",
          dataIndex: "times",
        },
        {
          title: "积分变化",
          width: 120,
          align: "center",
          dataIndex: "score",
          scopedSlots: {customRender: "score"},
        },
        {
          title: "任务说明",
          // width: 400,
          dataIndex: "remark",
          align: "center",
        },
        {
          title: "排序",
          width: 80,
          align: "center",
          dataIndex: "sortNumber",
        },
        {
          title: "操作",
          width: 100,
          align: "center",
          dataIndex: "operation",
          scopedSlots: {customRender: "operation"},
        },
        {}
      ];
    },
  },

  mounted() {
    this.search();
  },
  methods: {
    editSuccess() {
      this.search();
    },
    add() {
      this.$refs.modifyModal.showModal(undefined, 'new');
    },
    modify(record) {
      this.$refs.modifyModal.showModal(record, 'update');
    },
  },
};
</script>

<style lang="less" scoped>
.textClick {
  // color: #1890ff;
  cursor: pointer;
  text-decoration: underline;
}

.img-list {
  display: flex;
  justify-content: center;
}
</style>
